#agent-profile-container {
    margin-top: 1rem;
}

#profile-pic {
    width: 250px;
    height: 250px;
}

.profile-content-section {
    margin-top: 1rem;
    padding-top: 1rem;
}

#agent-info-table {
    width: 50%;
    margin: auto;
}

#agent-info tr {
    vertical-align: top;
}

#agent-info tr td{
    padding-top: 10px;
    list-style: none;
}

#agent-info tr td li{
    padding-bottom: 5px;
}

#profile-bio {
    padding-left: 5rem;
}

#accept-link-dd{
    padding-right: 0;
}

.link-btn-icon{
    float: right;
    margin-right: 20px;
}

#pending-request-btn {
    padding-right: 0;
    cursor: auto;
    border: none;
    outline: none;
}

#pending-request-btn:active, #pending-request-btn:hover, #pending-request-btn:focus {
    background-color: #33b5e5 !important;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    border: none;
    outline: none;
}
#pending-request-btn .link-btn-icon {
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
    outline: none;
    box-shadow: none;
}

#pending-request-btn .link-btn-icon:hover {
    color: #ff3c3c;
}

#connected-btn-dd {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    padding-right: 0;
}