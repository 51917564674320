.round {
    position: relative;
    width: 35px;
    height: 35px;
    display: inline-block;
    /*margin-right: 20px;*/
}

.round label {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.round label:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 9px;
    left: 8px;
    opacity: 0;
    position: absolute;
    top: 10px;
    transform: rotate(-45deg);
    width: 18px;
}

.round .task-toggle-btn {
    visibility: hidden;
}

.round .task-toggle-btn.toggled + label {
    background-color: #8d282b;
    border-color: #8d282b;
}

.round .task-toggle-btn.toggled + label:after {
    opacity: 1;
}

.star-btn, .star-btn:hover, .star-btn:focus {
    background: transparent;
    border: none;
    outline: none;
}

.star-btn {
    background: transparent;
    border: none;
    outline: none;
}

.fa-star {
    color: #4242ea;
}

.fa-star-o {
    color: #616161;
}