.error-content {
    border-radius: 50px;
    background: #ffffff;
    box-shadow:  20px 20px 60px #d9d9d9;
    margin-top: 30px;
    padding: 35px;
    background: url("../../media/images/error-img.png");
    background-repeat: no-repeat;
    height: 400px;
    background-size: contain;
}

.error-msg {
    padding-top: 35px;
}

.error-msg p {
    font-size: 1.5rem;
}

#error-img-container {
    text-align: center;
}

#error-img-container img{
    width: 75%;
}