#landing-page-container{
    margin-top: 2rem;
}

.content-container {
    border-radius: 50px;
    background: #ffffff;
    box-shadow:  20px 20px 60px #d9d9d9;
    margin: 1rem;
    min-height: 80vh;
}

.content-heading {
    font-size: 2.5rem;
    margin: 2rem;
}