a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
}

html { font-size: 14px; }

@media (min-width: 768px) {
    html { font-size: 16px; }
}

.box-shadow { box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05); }

#header-nav {
    width: 85%;
    margin: auto;
    margin-bottom: 0;
    border-radius: 35px;
}

.logo {
    width: 200px;
}

.mini-profile-pic{
    width: 50px;
    height: 50px;
    border-radius: 50px;
    object-fit: cover;
    object-position: center;
}

.drop-menu {
    padding: 5px 10px 5px 5px;
    margin: 0;
    border-radius: 50px;
    border: none;
    box-shadow: none;
}

/*.drop-menu:hover, .drop-menu:focus {*/
/*    box-shadow: none;*/
/*}*/

/*.active {*/
/*    box-shadow:  0 2px 10px 0 rgba(0, 0, 0, 0.12);*/
/*}*/

.dropdown-menu {
    border-radius: 15px;
}

.drop-menu-name {
    padding: 0 15px;
    font-size: 0.9rem;
}