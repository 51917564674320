#connections-container {
    margin-top: 1rem;
}

#client-item-col{
    margin: auto;
}

.clients-headers {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#search-clients-input {
    width: 20vw;
    padding-right: 60px;
}

#clear-search-btn {
    visibility: hidden;
    margin-left: -60px;
    padding: 3px 7px;
    border: none;
    outline: none;
    box-shadow: none;
}

#client-search-btn {
    padding: 3px 7px;
    margin: 0;
    border: none;
    outline: none;
    box-shadow: none;
}

.clients-list {
    padding-bottom: 5vh;
}

#pagination-footer {
    margin-bottom: 3rem;
}

.page-arrow {
    padding: 0 5vw;
}