/* Provide sufficient contrast against white background */
a {
  color: #8b171a;
}

a:hover, a:active, a:focus {
  color: #8d282b;
}

code {
  color: #E01A76;
}

#main-layout-container {
  margin-top: 0;
  padding-top: 0;
  margin-bottom: 100px;
}

.btn-one {
  background-color: #8b171a !important;
  color: #ffffff;
  /*border: 5px solid #8b171a;*/
}

.btn-one:active, .btn-one:hover {
  color: #ffffff;
}

.btn-one:active, .btn-one.active {
  background-color: #6c1114 !important;
}

.btn-two {
  background-color: #c66320;
  color: #ffffff;
}

.btn-two:active, .btn-two:hover {
  color: #ffffff;
}

.btn-two:active {
  background-color: #a6531a;
}