.login {
  width: 25vw;
  min-width: 400px;
  margin: 10vh auto;
  padding: 10px 20px 20px 20px;
}

/*.login * {*/
/*  margin-top: 8px;*/
/*}*/

.login #forgot-password-link {
  background-color: transparent;
  border: none;
}

.login #forgot-password-link:focus {
  background-color: transparent;
  box-shadow: none;
}


#header {
  text-align: left;
  margin-bottom: 30px;
}

#login-btn,
#register-btn {
  width: 100%;
}

/*.Loginhome * {*/
/*        */
/*  border-radius: 50px;*/
/*  background: #55b9f3;*/
/*  box-shadow:  13px 13px 26px #489dcf, */
/*              -13px -13px 26px #62d5ff;*/
/*}*/