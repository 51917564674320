#plaid-link-btn {
    padding-right: 10px;
    padding-left: 10px;
}

#welcome-modal .modal-dialog .modal-content{
    padding: 20px;
}

.modal-overlay {
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 60%;
    display: none;
}

.client-home-container {
    margin-top: 30px;
    padding-bottom: 15px;
    border: 1px solid #eaeaea;
    border-radius: 15px;
    box-shadow: 5px 5px 20px #d9d9d9;
}

#task-list-search {
    width: 250px;
    margin-right: 10px;
}

#clear-task-search-btn {
    visibility: hidden;
    margin-left: -30px;
    padding: 3px 7px;
    border: none;
    outline: none;
    box-shadow: none;
}

.task-action-btn {
    padding: 0;
    border: none;
    box-shadow: none;
    outline: none;
    font-size: 0.9rem;
    margin-top: 30px;
}

.task-action-btn:hover, .task-action-btn:active, .task-action-btn:focus {
    border: none;
    box-shadow: none;
    outline: none;
}

#task-delete-btn {
    color: #ff2c2e;
    margin-left: 15px;
}

#add-task-btn {
    margin-right: 25px;
    color: #5c697d;
}

#add-task-btn:hover {
    color: #1f5297;
}

#complete-tasks-btn {
    margin-left: 25px;
    color: #5d7d5c;
}

#complete-tasks-btn:hover {
    color: #167f13;
}

#delete-tasks-btn {
    margin-left: 25px;
    color: #7d5c5c;
}

#delete-tasks-btn:hover {
    color: #971f1f;
}

input[type=checkbox].task-check-box {
    -ms-transform: scale(1.5); /* IE */
    -moz-transform: scale(1.5); /* FF */
    -webkit-transform: scale(1.5); /* Safari and Chrome */
    -o-transform: scale(1.5); /* Opera */
}

.task-header-col {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    flex: 1 1 auto;
    min-height: 1px;
}

#task-list-header {
    font-size: 2.25rem;
    padding-left: 10%;
    padding-top: 20px;
    padding-bottom: 15px;
}

#budget-category-header {
    padding-bottom: 15px;
}

.unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.budget-category-row {
    padding-top: 15px;
    padding-bottom: 15px;
    cursor: pointer;
}

.budget-category-row:hover {
    background-color: #f5f5f5;
}

.expense-row {
    background-color: #eeeeee;
    padding-top: 5px;
    padding-bottom: 5px;
}

.category-header {
    font-weight: 400;
}

#net-remaining-pos {
    color: #1e791e;
}

#net-remaining-neg {
    color: #a71e1e;
}

.add-task-btn-icon {
    cursor: pointer;
}

.add-task-text {
    color: #226bb8;
    cursor: text;
    font-size: 1.2rem;
}

.task-title {
    display: inline-block;
}

.task-checkbox-container {
    margin: auto;
    text-align: center;
}

@media screen and (max-width: 768px){
    .task-checkbox-container {
        margin-right: 20px;
    }
}

.task-title-container {
    margin-bottom: 10px;
    font-size: 1.1rem;
}

.task-deadline-container, .task-organizer-container {
    font-size: 0.9rem;
    font-weight: 400;
    color: #616161;
    display: inline-block;
}

.task-organizer-container {
    margin-right: 25px;
}

.past-due-text {
    font-size: 0.9rem;
    font-weight: 400;
    color: #d52a2a;
    display: inline-block;
}

.task-deadline-container span{
    margin-left: 8px;
    margin-right: 15px;
}

.task-favorite-container {
    margin: auto;
    text-align: right;
    margin-right: 10px;
}

.task-edit-btn, .task-edit-btn:hover, .task-edit-btn:focus {
    background: none;
    outline: none;
    border: none;
    padding: 0;
}

.task-edit-btn:hover {
    text-decoration: underline;
}

#task-info-modal {
    transition: transform .3s linear, opacity .3s ease-in;
    z-index: 99;
    padding: 15px;
    background: #ffffff;
    position: fixed;
}

@media screen and (min-width: 769px) {
    #task-info-modal {
        height: 500px;
        width: 340px;
        right: 25px;
        bottom: 50px;
    }
}

@media screen and (max-width: 768px) {
    #task-info-modal {
        width: 100%;
        height: 100%;
        left: 0;
        bottom: 0;
    }
}

#task-info-modal.visible {
    transform: translateX(0);
    opacity: 1;
}



#task-info-modal.hidden {
    transform: translateX(400px);
    opacity: 0;
}

#task-info-fav-checkbox {
    text-align: left;
}

#close-task-info-btn {
    color: #616161;
    background: none;
    border: none;
    outline: none;
    font-size: 1.1rem;
}

#close-task-info-btn:focus, #close-task-info-btn:hover {
    color: #af3737;
    background: none;
    border: none;
    outline: none;
}

#task-info-edit-title {
    width: 100%;
}

#task-info-edit-details {
    resize: none;
    height: 100%;
}

.edit-details-row {
    margin-top: 15px;
    height: 270px;
}

#new-task-title {
    width: 80%;

}

#completed-text {
    font-size: 0.82rem;
    color: #1e791e;
    margin-left: 25px;
}

#completed-text span {
    margin-left: 3px;
}