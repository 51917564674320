#account-settings-nav {
    margin-top: 0;
    padding-top: 0;
}

#bank-container {
    margin-top: 3rem;
}

.bank-card-header {
    cursor: pointer;
    margin: 0;
}

.open-account-icon {
    color: #636363;
}

.account-details .row {
    padding: 10px;
    margin: 0;
}

.account-header {
    padding: 15px 10px 5px 10px;
    margin: 0;
}

.icon-btn, .icon-btn:focus, .icon-btn:hover {
    border: none;
    box-shadow: none;
    outline: none;
    padding: 2px;
    margin: 0;
}

.edit-account-icon {
    color: #2f7bff;
}

.edit-delete-icon {
    color: #ff2c2e;
}

.fa-angle-down {
    transform: rotate(360deg);
    transition: 250ms transform;
}

.fa-angle-down.accounts-expanded {
    animation: spin 250ms;
    transform: rotate(180deg);
}

.fa-angle-down.accounts-collapsed {
    animation: spin 250ms;
    transform: rotate(0deg);
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(180deg);
    }
}

.settings-content {
    border-radius: 50px;
    background: #ffffff;
    box-shadow: 20px 20px 60px #d9d9d9;
    padding: 25px;
}

.custom-file {
    width: 250px;
}

#profile-pic-input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

#profile-pic-input + label {
    color: #2586d9;
    display: inline-block;
    cursor: pointer;
    padding: 5px;
    margin-top: 5px;
    border-radius: 5px;
    position: relative;
    bottom: 37px;
    left: 68px;
}

#profile-pic-input:focus + label,
#profile-pic-input + label:hover {
    text-decoration: underline;
}

#profile-pic-input:focus + label {
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
}

#profile-pic {
    width: 250px !important;
    height: 280px !important;
    box-shadow: 20px 20px 60px #d9d9d9;
    object-fit: cover;
    object-position: center;
}

.img-thumbnail{
    padding-bottom: 30px;
}

#profile-name {
    font-size: 1.65rem;
    font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
}

#contact-info-section {
    padding: 25px 0;
}

#contact-info-section .md-form {
    margin-top: 0;
    margin-bottom: 0;
}

.content-header {
    padding-bottom: 10px;
    font-size: 1.65rem;
    font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
    font-weight: 400;
    color: #656565;
}

#edit-btn {
    color: #226bb8;
}

#save-btn {
    color: #1dac43;
}

#discard-btn {
    color: #e22c2c;
    padding-left: 10px;
}

.settings-btn {
    border: none;
    box-shadow: none;
    outline: none;
    padding: 2px;
    margin: 0;
}

.settings-btn:hover {
    border: none;
    box-shadow: none;
    outline: none;
    padding: 2px;
    margin: 0;
}

.settings-btn:focus {
    border: none;
    box-shadow: none;
    outline: none;
    padding: 2px;
    margin: 0;
}

.settings-notification-column {
    position: absolute;
    left: 40px;
    bottom: 40px;
    z-index: 2000;
    width: 250px;
    list-style: none;
    padding-left: 0;
}

.settings-notification-modal {
    margin-top: 10px;
    /*position: absolute;*/
    /*left: 40px;*/
    /*bottom: 40px;*/
    /*z-index: 2000;*/
    /*width: 250px;*/
}

.settings-notification-modal .modal-xs {
    margin: 0;
}

.settings-notification-modal .modal-xs .modal-content {
    width: 250px;
}

.settings-notification-modal .modal-header {
    padding: 5px;
}

.settings-notification-modal .modal-title {
    text-align: center;
    width: 100%;
}

.settings-notification-modal .close {
    padding: 2px 6px;
    margin: 0;
}

#side-menu-links{
    list-style: none;
}

#side-menu-links *{
    margin: 0;
    padding: 5px 0 0 0;
    font-size: 0.85rem;
    font-weight: 300;
}

.general-settings-body {
    padding-top: 25px;
    width: 85%;
    margin: auto;
}

.general-settings-body table {
    width: 25rem;
    margin: auto;
}

.general-settings-body td:nth-child(even) {
    width: 300px;
}

.general-settings-body td:nth-child(odd) {
    width: 150px;
}

.general-settings-body .row {
    padding-top: 10px;
}

.general-settings-body .form-control{
    padding: 2px;
    margin: 2px;
}

.general-settings-body .col{
    margin: auto;
}

.update-login-icon{
    color: #2f7bff;
}