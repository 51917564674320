.budget-card {
    border-radius: 50px;
    background: #ffffff;
    border: 1px solid #dee2e6;
}

.budget-danger {
    color: #c0392b;
}

.budget-success {
    color: #2d8631;
}

.consultant-budget-container {
    margin: 1rem 1rem 1rem -1px;
    padding: 1.5rem;
    border-radius: 50px;
    background: #ffffff;
    border: 1px solid #dee2e6;
    min-height: 400px;
    width: 100%;
}

#budget-back-btn{
    font-size: 1rem;
    padding: 1px 15px;
    margin: auto 8px;
    vertical-align: middle;
    color: #747474;
}

#budget-back-btn span {
    padding-left: 10px;
}

#budget-back-btn:hover, #budget-back-btn:focus, #budget-back-btn:active {
    text-decoration: none;
    color: #404040;
}

#edit-budget-btn{
    font-size: 1rem;
    padding: 1px 15px;
    margin: auto 8px;
    vertical-align: middle;
    color: #5a80d9;
}

#edit-budget-btn:hover, #edit-budget-btn:focus, #edit-budget-btn:active {
    text-decoration: none;
    color: #344e90;
}

.category-card {
    width: 90%;
    margin: 15px;
    height: 12rem;
}

.edit-category-card {
    width: 90%;
    margin: 15px;
    height: 20rem;
}

#add-category-body {
    margin: auto;
}

#add-category-btn {
    border: none;
    outline: none;
    box-shadow: none;
    color: #828282;
}

#add-category-btn:hover, #add-category-btn:active, #add-category-btn:focus {
    border: none;
    outline: none;
    box-shadow: none;
    color: #5c5c5c;
}

.delete-btn {
    padding: 5px;
    margin: 0;
    border: none;
    box-shadow: none;
    outline: none;
    color: #bd2130;
}

.delete-btn:hover, .delete-btn:active, .delete-btn:focus {
    color: #b01f2d;
    border: none;
    box-shadow: none;
    outline: none;
}

.budget-input-form {
    width: 15rem;
    max-width: 100%;
    float: right;
}

#category-select {
    width: 300px;
    margin: auto;
}

.empty-budget {
    font-size: 1.5rem;
    padding-top: 20px;
}

.left-side-nav {
    border-left: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
    border-top: 1px solid #dee2e6;
    border-right: 1px solid #ffffff;
    border-bottom-left-radius: 15px;
    border-top-left-radius: 15px;
    padding: 5px;
    margin-top: 100px;
}

.left-side-nav li {
    list-style: none;
}

#nav-budget-btn:hover, #nav-task-list-btn:hover {
    border: none;
    outline: none;
    background-color: transparent;
    box-shadow: none;
}

.left-side-nav li {
    padding: 10px 10px 6px;
    border: none;
    outline: none;
    background-color: transparent;
    box-shadow: none;
    margin: 5px;
    text-align: center;
    border-radius: 50px;
}

.left-side-nav li * :nth-child(2) {
    border-bottom: 4px solid transparent;
}

.left-side-nav #nav-budget-btn {
    color: #25b465;
}

.left-side-nav #nav-budget-btn.active :nth-child(2){
    border-bottom: 4px solid #25b465;
}

.left-side-nav #nav-budget-btn.active:hover :nth-child(2){
    border-bottom: 4px solid #1a7d46;
}

.left-side-nav #nav-budget-btn:hover {
    color: #1a7d46;
}

.left-side-nav #nav-task-list-btn {
    color: #1486d2;
}

.left-side-nav #nav-task-list-btn.active :nth-child(2){
    border-bottom: 4px solid #1486d2;
}

.left-side-nav #nav-task-list-btn.active:hover :nth-child(2){
    border-bottom: 4px solid #0f5a8b;
}

.left-side-nav #nav-task-list-btn:hover {
    color: #0f5a8b;
}

h3.display {
    font-size: 2.5rem;
}