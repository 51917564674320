.register {
  width: 40vw;
  min-width: 400px;
  margin: 10vh auto;
  padding: 10px 20px 20px 20px;
}

#header {
  text-align: left;
  margin-bottom: 20px;
}

#personal-registration #register-btn {
  background-color: rgba(3, 180, 3, 0.884);
  color: #ffffff;
}

#personal-registration #register-btn:active {
  background-color: rgba(1, 141, 1, 0.884);
  color: #ffffff;
}

#car-ownership-form {
  margin-top: 20px;
}

#register-btn, #back-btn, #next-btn {
  width: 100%;
}

#requirements-info-btn {
  font-size: large;
  padding: 5px 14px;
  border-radius: 1500px;
}

.modal-dialog {
  min-width: 25vw;
}

.md-form>label {
  overflow: visible;
}