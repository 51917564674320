#consultant-profile-container {
    margin-top: 1rem;
}

#profile-pic {
    width: 250px;
    height: 250px;
}

.profile-content-section {
    margin-top: 1rem;
    padding-top: 1rem;
}

#info-list {
    list-style: none;
}

#info-list * {
    padding-bottom: 10px;
}

#profile-bio {
    padding-left: 5rem;
}

#accept-link-dd{
    padding-right: 0;
}

.link-btn-icon{
    float: right;
    margin-right: 20px;
}

#pending-request-btn {
    padding-right: 0;
    cursor: auto;
    border: none;
    outline: none;
}

#pending-request-btn:active, #pending-request-btn:hover, #pending-request-btn:focus {
    background-color: #33b5e5 !important;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    border: none;
    outline: none;
}
#pending-request-btn .link-btn-icon {
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
    outline: none;
    box-shadow: none;
}

#pending-request-btn .link-btn-icon:hover {
    color: #ff3c3c;
}

#connected-btn-dd {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    padding-right: 0;
}

.password-row {
    width: 85%;
    margin: auto;
}

.password-row .col input {
    margin-bottom: 0
}

.error-msg{
    color: #f44336;
    padding-top: 0 !important;
}

.criteria-col {
    height: 50%;
    margin: auto;
    padding: 0;
}

.criteria-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.criteria-list li.invalid {
    color: #f44336;
}

.criteria-list li.valid {
    color: #4caf50;
}

.criteria-list li.invalid:after{
    content: '\f00d';
    font-family: FontAwesome;
    margin:0px;
    padding-left: 5px;
}

.criteria-list li.valid:after{
    content: '\f00c';
    font-family: FontAwesome;
    margin:0px;
    padding-left: 5px;
}

.consultant-container {
    margin-top: 1rem;
}

.consultant-tab-container{
    padding: 20px;
}

#client-item-col{
    margin: auto;
}

.clients-headers {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.clients-list {
    padding-bottom: 5vh;
}

.request-action-btn {
    border: none;
    padding: 0;
    box-shadow: none;
    outline: none;
    margin-right: 20px;
}

.request-action-btn:hover, .request-action-btn:focus, .request-action-btn:active {
    border: none;
    box-shadow: none;
    outline: none;
}

#approve-btn {
    color: #4caf50;
}

#approve-btn:hover {
    color: #246827;
}

#reject-btn {
    color: #e22c2c;
}

#reject-btn:hover {
    color: #951e1e;
}

#search-connected-form{
    width: 400px;
    margin: auto;
}

.client-card {
    width: 18rem;
    margin: 15px;
}

#client-cards-row {
    width: 100%;
}

.client-profile-picture {
    object-fit: cover;
    object-position: center;
}