#connections-container {
    margin-top: 1rem;
}

.collaborators-headers {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#search-collaborators-input {
    width: 20vw;
    padding-right: 60px;
}

#connections-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    vertical-align: middle;
    padding: 0
}

#email-icon-button, #msg-icon-button{
    padding: 0;
    margin: 0;
    border: none;
    outline: none;
    box-shadow: none;
}

#collaborator-item-col{
    margin: auto;
}

#connections-side-nav{
    margin: 3rem 0 0 0;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
}

#collaborator-search-btn {
    padding: 3px 7px;
    margin: 0;
    border: none;
    outline: none;
    box-shadow: none;
}

.collaborators-list {
    padding-bottom: 5vh;
}

#request-action-btns {
    margin-top: 1rem;
}

#clear-search-btn {
    visibility: hidden;
    margin-left: -60px;
    padding: 3px 7px;
    border: none;
    outline: none;
    box-shadow: none;
}

#pagination-footer {
    margin-bottom: 3rem;
}

.page-number {
    padding: 0 7px;
}

.page-arrow {
    padding: 0 5vw;
}

.paging-btn {
    padding: 0;
    border: none;
    outline: none;
    box-shadow: none;
    font-size: 0.9rem;
}

.paging-btn:hover {
    padding: 0;
    border: none;
    outline: none;
    box-shadow: none;
}

.page-number.selected-page{
    font-weight: bold;
    cursor: default;
    font-size: 1.15rem;
}

#professions {
    margin: 0;
    padding: 0;
}

#professions li {
    list-style: none;
    font-size: 0.85rem;
    padding: 5px 0;
    margin: 0;
}