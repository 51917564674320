#calendar-container {
    height: 85vh;
    margin-top: 1rem;
}

#event-time {
    text-align: right;
    width: 100%;
}

#event-details {
    padding: 40px 25px;
}

#event-from-row {
    padding: 5px;
}

#event-to-row {
    padding: 5px;
}

.date-container {
    display: none;
}

#new-event-form .row {
    padding: 8px 0;
}

#details-row {
    margin-top: 12px;
}

#edit-event-details {
    display: none;
}

#event-details-textarea {
    width: 100%;
    resize: vertical;
}

#all-day-details-row {
    padding-bottom: 10px;
}

#edit-all-day-details-row {
    padding-bottom: 17px;
    display: none;
}

.edit-date-col {
    display: none;
}

#edit-event-title {
    display: none;
    font-size: 24px;
}

#footer-container {
    width: 100%;
    border: 1px solid black;
}

#edit-details-btn-group {
    display: none;
}

.notification-column {
    position: absolute;
    left: 40px;
    bottom: 40px;
    z-index: 2000;
    width: 250px;
    list-style: none;
    padding-left: 0;
}

.notification-modal {
    margin-top: 10px;
    /*position: absolute;*/
    /*left: 40px;*/
    /*bottom: 40px;*/
    /*z-index: 2000;*/
    /*width: 250px;*/
}

.notification-modal .modal-xs {
    margin: 0;
}

.notification-modal .modal-xs .modal-content {
    width: 250px;
}

.notification-modal .modal-header {
    padding: 5px;
}

.notification-modal .modal-title {
    text-align: center;
    width: 100%;
}

.notification-modal .close {
    padding: 2px 6px;
    margin: 0;
}

.attendee-tag {
    background-color: #eeeeee;
    /*color: #ffffff;*/
    padding: 2px 5px;
    margin-right: 5px;
    margin-top: 5px;
    border-radius: 5px;
    float: right;
    display: block;
}

.attendees-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.attendee-tag button {
    padding: 0;
    margin: 0 0 0 7px;
    border: none;
    box-shadow: none;
    color: #ffffff;
    background-color: transparent;
    font-size: .9rem;
    outline: none;
}

.MuiTextField-root {
    border: none;
}

#editable-attendee-list {
    display: none;
}

.title-btn {
    padding: 3px 5px;
    color: #5f5f5f;
    border: none;
    outline: none;
    box-shadow: none;
}

.title-btn:focus {
    color: #5f5f5f;
    box-shadow: none;
    outline: none;
}

.title-btn:hover {
    color: #444444;
    box-shadow: none;
    outline: none;
}