.forgot-password-content {
    margin-top: 30px;
    border-radius: 50px;
    background: #ffffff;
    box-shadow: 20px 20px 60px #d9d9d9;
    padding: 25px;
}

.reset-password-section {
    width: 50%;
    margin: 30px auto 0;
}